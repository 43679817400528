import axios from 'axios'
import Vue from 'vue'
import store from '@/store/store'
import {getCookie, hideLoading, requestExist, showLoading} from '../../assets/js/functions'

const protocol = window.location.protocol
let domain = window.location.hostname
domain = window.location.hostname.split('.').reverse()
if (domain.length > 3) domain = domain.splice(0, 3)
domain = domain.reverse().join('.')
domain = `${protocol}//${domain}/api`

const baseURL = process.env.VUE_APP_BASE_URL || domain
const API = axios.create({
  baseURL
  // You can add your headers here
})

const cancelToken = axios.CancelToken

API.interceptors.request.use(function (config) {
  // Do something before request is sent

  // throw new API.Cancel('you send sevaral request')
  // console.log(new cancelToken((cancel) => cancel('cancel repeat')).reason.message)

  config.headers['Cache-Control'] = 'no-store'

  const exceptUrls = ['v1/portal/profile/browser-settings', 'broadcasting/auth']

  if (config.method !== 'get' && exceptUrls.indexOf(config.url.split('?')[0]) === -1) {
    showLoading()
  }

  return config

}, function (error) {
  // Do something with request error
  // console.log('rejected')
  return Promise.reject(error)
})

API.interceptors.response.use(function (response) {
  // console.log(response.config)
  if (response.config.method !== 'get') {
    hideLoading()
  }

  if (response.headers.date) {
    store.dispatch('helper/changeCurrentTime', response.headers.date)
  }
  return response
}, function (error) {
  const loggedIn = localStorage.getItem('accessToken') || getCookie('accessToken')

  hideLoading()

  const exceptUrls = ['broadcasting/auth']

  if (exceptUrls.indexOf(error.response.config.url) === -1) {
    if (loggedIn && error.response && error.response.status === 401) {
      store.dispatch('auth/logout')
    }
    // console.log(store.state.helper.requestStatus)
    if (loggedIn && error.response && error.response.status === 403) {
      Vue.prototype.$vs.notify({
        title: Vue.prototype.noAccessNotify.title,
        text: Vue.prototype.noAccessNotify.text,
        icon: 'icon-alert-circle',
        iconPack: 'feather',
        time: 2400,
        color: 'danger'
      })
      store.dispatch('auth/logout')
    }
  }

  return Promise.reject(error)
})


export default API
