import axios from '@/http/axios'

export function getProvinces (id) {

  return axios({
    url: `v1/portal/countries/${id}/provinces`,
    method: 'get'
  })
}

export function getAllProvinces () {

  return axios({
    url: `v1/portal/provinces`,
    method: 'get'
  })
}
export function getCountries () {

  return axios({
    url: 'v1/portal/countries',
    method: 'get'
  })
}

export function getCities (countryId, provinceId) {

  return axios({
    url: `v1/countries/${countryId}/provinces/${provinceId}/cities`,
    method: 'get'
  })
}

export function getAllCities () {

  return axios({
    url: `v1/portal/cities`,
    method: 'get'
  })
}
