<template>
  <div class="suggest-box">
    <transition :css="false"
                @before-enter="beforeEnterRow"
                @enter="enterRow"
                @leave="leaveRow">
      <ul class="suggest-list" v-if="show && suggests.length">
        <li v-for="(suggest, suggest_index) in suggests"
            :key="suggest_index"
            @click="$emit('suggest:selected', suggest), show = false">
          {{ suggest.label }}
        </li>
      </ul>
    </transition>

    <div v-if="show"
         class="suggest-box-overlay"
         @click="show = false"></div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'suggestBox',
  props: {
    suggests: {}
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    beforeEnterRow (el) {
      // el.style.opacity = 0
      el.style.maxHeight = 0
    },
    enterRow (el, done) {
      gsap.to(el, {
        // opacity: 1,
        maxHeight: 135,
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    },
    leaveRow (el, done) {
      gsap.to(el, {
        // opacity: 0,
        maxHeight: 0,
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.suggest-box {
  overflow: hidden;

  ul {
    position: absolute;
    top: auto;
    right: 0;
    left: 0;
    background: #ffffff;
    z-index: 500000;
    max-height: 135px;
    overflow: auto;
    border-radius: 0 0 .5rem .5rem;
    border: 1px solid #cdcdcd;
    padding: 0 5px;

    &::-webkit-scrollbar {
      display: block;
      border-radius: 4px;
      width: 3px;
      background: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      border-radius: 5px;
      background: #cecece;
    }

    li {
      border-top: 1px solid #cdcdcd;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 30px;

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-radius: 0 0 .5rem .5rem;
      }
    }
  }

  .suggest-box-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 49000;
  }
}
</style>
