import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
  getCashBoxes: createCancelTokenHandler('getCashBoxes'),
  getCashBoxesTrash: createCancelTokenHandler('getCashBoxesTrash'),
  getCashBoxTransactions: createCancelTokenHandler('getCashBoxTransactions'),
}

function makeQuery (page = 1, filters = {}, sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  const filterKeys = Object.keys(filters)
  if (filterKeys.length) {
    filterKeys.forEach((key) => {
      if (filters[key]) {
        queries.push(`${key}=${filters[key]}`)
      }
    })
  }

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getCashBoxes (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

  return axios({
    url: `v1/portal/treasury/cashes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCashBoxes'].handleRequestCancellation().token
  })
}

export function getCashBoxesTrash (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  queries = queries.length > 0 ? `&${  queries.join('&')}` : ''

  return axios({
    url: `v1/portal/treasury/cashes?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCashBoxesTrash'].handleRequestCancellation().token
  })
}

export function getCashBox (id) {
  return axios({
    url: `v1/portal/treasury/cashes/${id}`,
    method: 'get'
  })
}

export function getCashBoxTransactions (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/portal/treasury/cashes/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCashBoxTransactions'].handleRequestCancellation().token
  })
}

export function getCashBoxActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/portal/treasury/cashes/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertCashBoxes (payment) {
  return axios({
    url: 'v1/portal/treasury/cashes',
    data: payment,
    method: 'post'
  })
}

export function editCashBoxes (payment) {
  return axios.patch(`v1/portal/treasury/cashes/${payment.id}`, payment)
}

export function restoreCashBoxes (id) {
  return axios({
    url: `v1/portal/treasury/cashes/${id}/restore`,
    method: 'put'
  })
}

export function deleteCashBoxes (id, force = false) {
  return axios({
    url: `v1/portal/treasury/cashes/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}
