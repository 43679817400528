import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
  getDebtorUsersOpeningInventories: createCancelTokenHandler('getDebtorUsersOpeningInventories'),
  getCreditorUsersOpeningInventories: createCancelTokenHandler('getCreditorUsersOpeningInventories'),
  getStoreroomsOpeningInventories: createCancelTokenHandler('getStoreroomsOpeningInventories'),
  getCashBoxesOpeningInventories: createCancelTokenHandler('getCashBoxesOpeningInventories'),
  getBanksOpeningInventories: createCancelTokenHandler('getBanksOpeningInventories'),
}

/* get opening inventories */
export function getOpeningInventories () {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories',
    method: 'get'
  })
}

/* lock opening inventories */
export function lockOpeningInventories () {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories/lock',
    method: 'post'
  })
}

/* unlock opening inventories */
export function unlockOpeningInventories () {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories/unlock',
    method: 'post'
  })
}

/* get, set and delete creditor users opening inventories */
export function getDebtorUsersOpeningInventories (page = 1) {
  let queries = []

  if (page > 1) {
    queries.push(`page=${page}`)
  }

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

  return axios({
    url: `v1/portal/accountancy/opening-inventories/debtor-users${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getDebtorUsersOpeningInventories'].handleRequestCancellation().token
  })
}

export function getDebtorUserOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/debtor-users/${id}`,
    method: 'get'
  })
}

export function importDebtorUserOpeningInventories (payment) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/debtor-users/import`,
    data: payment,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post'
  })
}

export function setDebtorUsersOpeningInventories (payment) {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories/debtor-users',
    data: {users: payment},
    method: 'post'
  })
}

export function deleteDebtorUsersOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/debtor-users/${id}`,
    method: 'delete'
  })
}

/* get, set and delete creditor users opening inventories */
export function getCreditorUsersOpeningInventories (page = 1) {
  let queries = []

  if (page > 1) {
    queries.push(`page=${page}`)
  }

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

  return axios({
    url: `v1/portal/accountancy/opening-inventories/creditor-users${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCreditorUsersOpeningInventories'].handleRequestCancellation().token
  })
}

export function getCreditorUserOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/creditor-users/${id}`,
    method: 'get'
  })
}

export function importCreditorUserOpeningInventories (payment) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/creditor-users/import`,
    data: payment,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post'
  })
}

export function setCreditorUsersOpeningInventories (payment) {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories/creditor-users',
    data: {users: payment},
    method: 'post'
  })
}

export function deleteCreditorUsersOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/creditor-users/${id}`,
    method: 'delete'
  })
}

/* get, set and delete cash boxes opening inventories */
export function getCashBoxesOpeningInventories (page = 1) {
  let queries = []

  if (page > 1) {
    queries.push(`page=${page}`)
  }

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

  return axios({
    url: `v1/portal/accountancy/opening-inventories/cashes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCashBoxesOpeningInventories'].handleRequestCancellation().token
  })
}

export function getCashBoxOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/cashes/${id}`,
    method: 'get'
  })
}

export function setCashBoxesOpeningInventories (payment) {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories/cashes',
    data: {cashes: payment},
    method: 'post'
  })
}

export function deleteCashBoxesOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/cashes/${id}`,
    method: 'delete'
  })
}

/* set banks opening inventories */
export function getBanksOpeningInventories (page = 1) {
  let queries = []

  if (page > 1) {
    queries.push(`page=${page}`)
  }

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

  return axios({
    url: `v1/portal/accountancy/opening-inventories/banks${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBanksOpeningInventories'].handleRequestCancellation().token
  })
}

export function getBankOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/banks/${id}`,
    method: 'get'
  })
}

export function setBanksOpeningInventories (payment) {
  return axios({
    url: 'v1/portal/accountancy/opening-inventories/banks',
    data: {banks: payment},
    method: 'post'
  })
}

export function deleteBanksOpeningInventories (id) {
  return axios({
    url: `v1/portal/accountancy/opening-inventories/banks/${id}`,
    method: 'delete'
  })
}
