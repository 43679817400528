<template>
  <div class="u-input u-phone-input">
    <div class="u-input-box" v-if="!disabled" :class="[`dir-${$vs.rtl ? 'rtl' : 'ltr'}`]" :style="getStyles">

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span
              class="u-input-label text-subtitle"
              :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <div class="u-select-part" v-click-outside="handleClickOutside">
        <div
            v-if="!selected.isDefault"
            class="u-selected-item text-h5-light"
            :class="{ 'open': open, 'disabled': disabled}"
            :style="styles"
            @click="!disabled ? open = !open : '', handleOpenSelect()"
        >

          <span class="u-county-code">
            {{ ` (${typeof selected === 'string' ? JSON.parse(selected).dialCode : selected.dialCode}) ` }}
          </span>&nbsp;
          <span class="u-county-name">
            {{ ` ${selected.name} ` }}
          </span>
          <div class="u_flag-wrapper">
            <span
                :class="['u_flag ltr', selected.iso2.toLowerCase()]"
            ></span>
          </div>
        </div>

        <transition name="fade">
          <div class="u-phone-input-items" id="selectItems" v-if="open" :class="[{ 'u-select-hide': !open }, `position-${selectOpenDirection}`]">
            <input
                v-if="searchable"
                type="text"
                class="u-search-country-code w-full p-0 text-center text-h5-light"
                :style="styles"
                :autocomplete="false"
                :name="Math.random()"
                @input="$emit('searched', $event)"
                v-model="search">

            <div
                v-for="(option, i) of countirySelectList"
                :key="i"
                class="text-h5-light"
                v-show="!option.hasOwnProperty('show') || option.show"
                @click="handleSelectCountryCode(option)">
              <span class="u-county-code">
                {{ ` (${typeof option === 'string' ? JSON.parse(option).dialCode : option.dialCode}) ` }}
              </span>&nbsp;

              <span class="u-county-name">
                {{ option.name }}
              </span>

              <div class="u_flag-wrapper">
                <span
                    :class="['u_flag', option.iso2.toLowerCase()]"
                ></span>
              </div>
            </div>
          </div>
        </transition>
<!--        <div class="u-select-overflow" v-if="open" @click="open = false"></div>-->
      </div>
    </div>

    <div class="u-input-box" :class="[{'is-not-valid': !data.isValid || notValid, 'disabled': disabled}, classes || {}, `dir-${$vs.rtl ? 'rtl' : 'ltr'}`]" :style="getStyles">

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="(labelPlaceholder || label) && disabled" class="u-input-label-box-center" :class="[labelPosition]">
          <span
              class="u-input-label text-subtitle"
              :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input
          type="text"
          :id="id"
          pattern="[0-9]*"
          inputmode="numeric"
          class="text-h5-light"
          :class="[{'disabled': disabled}, `dir-${$vs.rtl ? 'rtl' : 'ltr'}`]"
          :style="inputStyles"
          :autocomplete="false"
          :placeholder="placeholder || getNumberExample"
          :disabled="disabled"
          :value="data.value"
          :name="Math.random()"
          @input="handleInputValue"
          @click="$emit('click')"
          @contextmenu="handleContextMenuClicked($event)"
          @keydown.enter="handleKeydownEnter"
      >
      <div class="phone-dial-code text-subtitle">
        {{ selected.dialCode }}
      </div>
    </div>

    <span class="u-input-errors text-body-1" v-if="!data.isValid && this.data.value.length > 0">
      {{ invalidText }}
    </span>
  </div>
</template>

<script>
import {
  parsePhoneNumberFromString,
  validatePhoneNumberLength,
  parseIncompletePhoneNumber,
  getExampleNumber,
  isValidPhoneNumber
} from 'libphonenumber-js/mobile'
import examples from 'libphonenumber-js/mobile/examples'
import allCountries from '../../assets/js/allCountries'
import { customParseFloat } from '../../assets/js/functions'

export default {
  name: 'UPhoneInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    id: {
      type: String,
      default: () => {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    autoFocus: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    label: {
      type: String,
      default: () => {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    labelPlaceholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    isEmpty: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    notValid: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    searchable: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    invalidText: {
      type: String,
      default: () => {
        return ''
      }
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    countirySelectList: {
      type: Array,
      default: () => {
        return allCountries
      }
    },
    defaultCountryCode: {
      type: String,
      default: () => { return '' }
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    classes: {
      type: [Object, Array, String],
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    inputStyles: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      closeDropdownTimer: 0,
      selectOpenDirection: 'bottom',
      selected: this.countirySelectList.length > 0 ? { ...this.countirySelectList[0], isDefault: true } : null,
      open: false,
      search: '',
      data: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    },
    getNumberExample () {
      return getExampleNumber(this.selected.iso2.toUpperCase(), examples).number.split(this.selected.dialCode).join('')
    }
  },
  created () {
    if (this.defaultCountryCode) {
      const countryIndex = this.countirySelectList.map(e => e.dialCode).indexOf(this.defaultCountryCode)
      if (countryIndex > -1) {
        this.selected = this.countirySelectList[countryIndex]
      }
    } else if (this.selected.isDefault) {
      const selectedIndex = this.countirySelectList.map(e => e.iso2).indexOf('IR')
      if (selectedIndex > -1 && this.selected.isDefault) {
        this.selected = this.countirySelectList[selectedIndex]
      }

      this.getDefaultCountry()
    }
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }

    if (this.id.length > 0 && this.autoFocus) {
      setTimeout(() => {
        // eslint-disable-next-line nuxt/no-globals-in-created
        window.document.getElementById(this.id).focus()
      }, 100)
    }
  },
  methods: {
    customParseFloat,
    handleOpenSelect () {
    },
    handleCloseDropDown (status) {
      if (status) {
        this.closeDropdownTimer = setTimeout(() => {
          if (!this.disabled) {
            this.open = false
          }
        }, 1000)
      } else {
        clearTimeout(this.closeDropdownTimer)
      }
    },
    handleSelectCountryCode (option) {
      this.selected = option
      this.open = false
      this.$emit('selected', option)
      this.search = option.iso2
      this.validateValue()
    },
    handleInputValue (event) {
      this.data.value = event.target.value
      this.validateValue(event)
      this.$emit('input', {
        value: parseIncompletePhoneNumber(`${this.selected.dialCode}${this.data.value}`),
        isValid: this.data.isValid
      })
    },
    validateValue (event) {
      if (this.data.value.length > 0 && this.data.value !== '0') {
        this.data.value = customParseFloat(this.data.value).toString()
      } else {
        this.data.value = ''
        if (event) {
          event.target.value = ''
        }
      }
      const parsePhone = parseIncompletePhoneNumber(`${this.selected.dialCode}${this.data.value}`)
      if (validatePhoneNumberLength(parsePhone) === 'TOO_LONG') {
        this.data.value = this.data.value.substr(0, this.data.value.length - 1)
        if (event) {
          event.target.value = this.data.value
        }
      }
      this.data.isValid = isValidPhoneNumber(`${this.selected.dialCode}${this.data.value}`)
    },
    initValues () {
      if (parsePhoneNumberFromString(this.value.value)) {
        const countryCode = parsePhoneNumberFromString(this.value.value).country
        const selectedIndex = this.countirySelectList.map(e => e.iso2).indexOf(countryCode)
        if (selectedIndex > -1) {
          this.selected = this.countirySelectList[selectedIndex]
        }
      }

      this.data.value = this.value.value.split(this.selected.dialCode).join('')
      this.validateValue()
    },
    getDefaultCountry () {
      return fetch('https://ip2c.org/s')
          .then(response => response.text())
          .then((response) => {
            const result = (response || '').toString().split(';')

            if (!result || result[0] !== '1') {
              throw new Error('unable to fetch the country')
            }

            const selectedIndex = this.countirySelectList.map(e => e.iso2).indexOf(result[1])
            if (selectedIndex > -1 && this.selected.isDefault) {
              this.selected = this.countirySelectList[selectedIndex]
            }
          })
          .catch(() => {
            const selectedIndex = this.countirySelectList.map(e => e.iso2).indexOf('IR')
            if (selectedIndex > -1 && this.selected.isDefault) {
              this.selected = this.countirySelectList[selectedIndex]
            }
          })
    },
    setSelectOpenPosition () {
      const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom
      const hasEnoughSpaceBelow = spaceBelow > 200
      if (hasEnoughSpaceBelow) {
        this.selectOpenDirection = 'bottom'
      } else {
        this.selectOpenDirection = 'top'
      }
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: `${this.selected.dialCode}${this.data.value}`
        },
        event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleClickOutside () {
      if (this.open) {
        this.open = false
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    },
    'data.isValid': {
      handler () {
        this.$emit('input', { value: parseIncompletePhoneNumber(`${this.selected.dialCode}${this.data.value}`), isValid: this.data.isValid })
      }
    },
    defaultCountryCode: {
      handler (val) {
        if (val) {
          const countryIndex = this.countirySelectList.map(e => e.dialCode).indexOf(val)
          if (countryIndex > -1) {
            this.selected = this.countirySelectList[countryIndex]
          }
        }
      },
      deep: true
    },
    search: {
      handler (val) {
        if (this.searchable) {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 100)

          if (!this.firstVisit) {
            // this.open = true

            this.countirySelectList.forEach((option) => {
              option.show = option.dialCode.toString().startsWith(`+${val.replace('+', '')}`) || option.name.toLowerCase().includes(val.toLowerCase())
            })
          }
        }
      },
      deep: true
    },
    open: {
      handler (val) {
        if (val) {
          window.document.body.classList.add('u-select-open')
          this.setSelectOpenPosition()
          setTimeout(() => {
            const searchInput = window.document.querySelector('.u-search-country-code')
            if (searchInput) {
              searchInput.focus()
            }
          }, 10)
        } else {
          window.document.body.classList.remove('u-select-open')
          this.search = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
