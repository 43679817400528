import { render, staticRenderFns } from "./customValidateTextarea.vue?vue&type=template&id=1b21d462&scoped=true&"
import script from "./customValidateTextarea.vue?vue&type=script&lang=js&"
export * from "./customValidateTextarea.vue?vue&type=script&lang=js&"
import style0 from "./customValidateTextarea.vue?vue&type=style&index=0&id=1b21d462&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b21d462",
  null
  
)

export default component.exports